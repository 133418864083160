<script>
    import { page } from '$app/stores'
    import { payment } from '$lib/stores'
    import { captureMessage } from '$lib/utilities/errorTrackingTools.js'
    import SiruAxios from '$lib/SiruAxios'
    import { _ } from 'svelte-i18n'

    let loading = false

    const onClickHandler = () => {
        if ($payment.uuid) {
            loading = true
            SiruAxios.post(
                `pay/rest/${$payment.uuid}/cancel`,
                {},
                {
                    useGlobalErrorHandler: false,
                },
            )
                .then(({ data }) => (window.location.href = data.redirect))
                .catch(() => {
                    captureMessage('Fallback to window.history.back() in CancelButton')
                    window.history.back()
                    loading = false
                })
        } else {
            window.history.back()
        }
    }
</script>

<div class="d-grid gap-2 text-center">
    <a
        href={$page.url.href}
        on:click|preventDefault={onClickHandler}
        class={`btn btn-link mb-3 text-decoration-none ${loading ? 'disabled' : ''}`}
    >
        {#if !loading}
            {$_('payment.button.cancel_payment')}{:else}
            <div class="spinner-border text-secondary spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        {/if}
    </a>
</div>

<style>
    .btn-link {
        color: var(--bs-gray-500);
    }
</style>
